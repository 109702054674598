var trident = !!navigator.userAgent.match(/Trident\/7.0/);
var net = !!navigator.userAgent.match(/.NET4.0E/);
var IE11 = trident && net
var IEold = (navigator.userAgent.match(/MSIE/i) ? true : false);
var theVideo;
var jwplayer;

if (jwplayer && jwplayer().onReady) {
    jwplayer().onReady(function () {
        theVideo = document.querySelector('video');
        theVideo.defaultPlaybackRate = 1.0;
        theVideo.playbackRate = 1.0;
        if (jwplayer().getRenderingMode() == "flash") {
            return;
        }
        $("#spinner-wrapper").fadeOut("slow", function () {
            $('#lesson-wrapper').removeClass('hidden');
        });
    });

    $(function () {
        var current = '1';
        $('.speed-menu li > a').click(function (e) {
            if ($(this).attr('speed')) {
                current = $(this).attr('speed');
                setSpeed(current);
            }
        });
        $('#speed-up').click(function (e) {
            var value = parseFloat(current);
            if (value < 2.0) {
                value += .50;
            } else {
                value = 1;
                setSpeed(value.toString());
            }
            current = value;
            setSpeed(current.toString());

        });
    });

    function setSpeed(speed) {
        switch (speed) {
            case '0.25':
                $('#speed').text('0.25');
                setPlayerSpeed(0.25);
                break;
            case '0.5':
                $('#speed').text('0.5');
                setPlayerSpeed(0.50);
                break;
            case '1':
                $('#speed').text('Normal');
                setPlayerSpeed(1.0);
                break;
            case '1.5':
                $('#speed').text('1.5');
                setPlayerSpeed(1.5);
                break;
            case '2':
                $('#speed').text('2');
                setPlayerSpeed(2.0);
                break;
            default:
                $('#speed').text('Normal');
                setPlayerSpeed(1.0);
                break;
        }
    }

    function setPlayerSpeed(speed) {
        if (IE11 || IEold) {
            jwplayer().seek(jwplayer().getPosition());
            jwplayer().onSeek(function () {
                theVideo.playbackRate = speed;
            });
            jwplayer().onPause(function () {
                theVideo.playbackRate = speed;
            });
            jwplayer().onPlay(function () {
                theVideo.playbackRate = speed;
            });
            theVideo.playbackRate = speed;
        } else {
            jwplayer().seek(jwplayer().getPosition());
            theVideo.playbackRate = speed;
        }
    }
}