$(function () {
  $.material.init();

  $('#show-btn').click(function () {
    $('.show-wrapper').show();
    $('.hide-wrapper').hide();
  });

  if (window.location.pathname === '/') {
    $(".sign-up").click(function () {
      $('#sales-page-wrapper').hide();
      $('#capture-form').show();
      $('#name').focus();
    });
    $('#footer').hide();
  }
  if (window.location.pathname === '/webinar') {
    $('#learn').hide();
    $('#footer').hide();
    setTimeout(function () {
      $('#learn').show();
      $('#question').hide();
    }, (1000 * 60) * 52);
  }

  if (window.location.pathname === '/') {
    var offer = new Date(2019, 2, 22, 23, 59, 59, 59);
    // offer.setTime();
    //new Date(year, month, date, hours, minutes, seconds, milliseconds);
    // Create Countdown on fire set value
    var Countdown = {
      // Backbone-like structure
      $el: $('.countdowntimer'),
      countdown_interval: null, // Params
      total_seconds: 0,
      // Initialize the countdown
      init: function () {
        // DOM
        this.$ = {
          days: this.$el.find('.bloc-time.days .figure'),
          hours: this.$el.find('.bloc-time.hours .figure'),
          minutes: this.$el.find('.bloc-time.min .figure'),
          seconds: this.$el.find('.bloc-time.sec .figure')
        };
        var today = new Date();
        //new Date(year, month, date, hours, minutes, seconds, milliseconds);
        var endDate = offer;

        var days = parseInt((endDate - today) / (1000 * 60 * 60 * 24));
        var hours = parseInt(Math.abs(endDate - today) / (1000 * 60 * 60) % 24);
        var minutes = parseInt(Math.abs(endDate - today) / (1000 * 60) % 60);
        var seconds = parseInt(Math.abs(endDate - today) / (1000) % 60);

        // Init countdown values
        this.values = {
          days: days,
          hours: hours,
          minutes: minutes,
          seconds: seconds,
        };

        // Initialize total seconds
        this.total_seconds = this.values.hours * 60 * 60 + (this.values.minutes * 60) + this.values.seconds;

        // Show Countdown
        $('.discount').removeClass('hidden');
        $('.countdown-container').show();

        // Animate countdown to the end 
        this.count();

      },

      count: function () {

        var that = this,
          $day_1 = this.$.days.eq(0),
          $day_2 = this.$.days.eq(1),
          $hour_1 = this.$.hours.eq(0),
          $hour_2 = this.$.hours.eq(1),
          $min_1 = this.$.minutes.eq(0),
          $min_2 = this.$.minutes.eq(1),
          $sec_1 = this.$.seconds.eq(0),
          $sec_2 = this.$.seconds.eq(1);

        this.countdown_interval = setInterval(function () {
          if (that.total_seconds > 0) {
            --that.values.seconds;
            if (that.values.minutes >= 0 && that.values.seconds < 0) {
              that.values.seconds = 59;
              --that.values.minutes;
            }
            if (that.values.hours >= 0 && that.values.minutes < 0) {
              that.values.minutes = 59;
              --that.values.hours;
            }
            // Days
            that.checkHour(that.values.days, $day_1, $day_2);
            // Hours
            that.checkHour(that.values.hours, $hour_1, $hour_2);
            // Minutes
            that.checkHour(that.values.minutes, $min_1, $min_2);
            // Seconds
            that.checkHour(that.values.seconds, $sec_1, $sec_2);
            --that.total_seconds;
          } else {
            clearInterval(that.countdown_interval);
          }
        }, 1000);
      },

      animateFigure: function ($el, value) {

        var that = this,
          $top = $el.find('.top'),
          $bottom = $el.find('.bottom'),
          $back_top = $el.find('.top-back'),
          $back_bottom = $el.find('.bottom-back');

        // Before we begin, change the back value
        $back_top.find('span').html(value);

        // Also change the back bottom value
        $back_bottom.find('span').html(value);

        // Then animate
        TweenMax.to($top, 0.8, {
          rotationX: '-180deg',
          transformPerspective: 300,
          ease: Quart.easeOut,
          onComplete: function () {

            $top.html(value);

            $bottom.html(value);

            TweenMax.set($top, {
              rotationX: 0
            });
          }
        });

        TweenMax.to($back_top, 0.8, {
          rotationX: 0,
          transformPerspective: 300,
          ease: Quart.easeOut,
          clearProps: 'all'
        });
      },

      checkHour: function (value, $el_1, $el_2) {

        var val_1 = value.toString().charAt(0),
          val_2 = value.toString().charAt(1),
          fig_1_value = $el_1.find('.top').html(),
          fig_2_value = $el_2.find('.top').html();

        if (value >= 10) {

          // Animate only if the figure has changed
          if (fig_1_value !== val_1) this.animateFigure($el_1, val_1);
          if (fig_2_value !== val_2) this.animateFigure($el_2, val_2);
        } else {
          // If we are under 10, replace first figure with 0
          if (fig_1_value !== '0') this.animateFigure($el_1, 0);
          if (fig_2_value !== val_1) this.animateFigure($el_2, val_1);
        }
      }
    };
    Countdown.init(); // Start timer
  }

  if (window.location.pathname === '/register') {
    $('#join-btn').click(function () {
      $(this).hide();
      $('#processing-btn').show();
    });
    if (typeof clientToken !== 'undefined') {
      braintree.setup(clientToken, "dropin", {
        container: "payment-form",
        paypal: {
          button: {
            type: 'checkout'
          }
        },
        onError: function (err, clientInstance) {
          $('#join-btn').show();
          $('#processing-btn').hide();
        }
      });
    }
  }

  $('.facebook-share').click(function (e) {
    var url = "https://www.learnmean.com/?s=social-share";
    var title = "The Official Full-Stack FREE Crash Course";
    var width = 666;
    var height = 353;
    var shareurl = 'http://www.facebook.com/sharer.php?u=' + encodeURIComponent(url) + '&t=' + encodeURIComponent(title);

    window.open(shareurl, 'sharer', 'toolbar=0,status=0,width=' + width + ',height=' + height);
  });

  $('.twitter-share').click(function (e) {
    var url = "https://www.learnmean.com/?s=social-share";
    var title = "The Official Full-Stack FREE Crash Course. #MEANStack #JavaScript";
    var width = 700;
    var height = 500;
    var via = "codewithintent_"
    var shareurl = "http://twitter.com/share?text=" + encodeURIComponent(title) + "&count=none&counturl=" + encodeURIComponent(url) + "&url=" + encodeURIComponent(url) + "&via=" + encodeURIComponent(via);

    window.open(shareurl, 'sharer', 'toolbar=0,status=0,width=' + width + ',height=' + height);
  });

  $('.linkedin-share').click(function (e) {
    var url = "https://www.learnmean.com/?s=social-share";
    var title = "The Official Full-Stack FREE Crash Course. #MEANStack #JavaScript";
    var summary = "Learn what it takes to become a Full-Stack developer with ths FREE crash course.";
    var width = 600;
    var height = 382;
    var shareurl = 'http://www.linkedin.com/shareArticle?mini=true&url=' + encodeURIComponent(url) + '&title=' + encodeURIComponent(title) + '&summary=' + summary;
    window.open(shareurl, 'sharer', 'toolbar=0,status=0,width=' + width + ',height=' + height);
  });

  $('.pinterest-share').click(function (e) {
    var url = "https://www.learnmean.com/?s=social-share";
    var title = "The Official Full-Stack FREE Crash Course. #MEANStack #JavaScript";
    var summary = "Learn what it takes to become a Full-Stack developer with ths FREE crash course.";
    var media = 'https://www.learnmean.com/images/share.jpg';
    var width = 700;
    var height = 500;
    var shareurl = 'http://pinterest.com/pin/create/button/?url=' + encodeURIComponent(url) + '&media=' + encodeURIComponent(media) + '&description=' + encodeURIComponent(title);

    window.open(shareurl, 'sharer', 'toolbar=0,status=0,width=' + width + ',height=' + height);
  });


  $('.email-share').click(function (e) {
    var url = "https://www.learnmean.com/?s=social-share";
    var title = "The Official Full-Stack FREE Crash Course.";
    var summary = "Learn what it takes to become a Full-Stack developer with ths FREE crash course. Sign up using this link " + url;
    window.open('mailto:?subject=' + title + '&body=' + summary, '_self');
  });

});